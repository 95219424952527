// Libraries
import React from 'react';
import Typist from 'react-typist';
import { SocialIcon } from 'react-social-icons';

// Styles
import './app.css';

function App() {
  return (
    <div className="page">
      <div className="landing">
        {/* Nav Bar Module */}
        <section id="header" class="desktop">
          <header className="header">
            <div className="header--navbar">
              <div className="header--navbar-logo">
                <a href="/">
                  <img src="/assets/site-logo.svg" alt="TH" />
                </a>
              </div>
              <div className="header--navbar-navigation">
                <nav>
                  <div className="header--navitems">
                    <ul>
                      <li id="header--navitem:home">
                        <a href="/">Home</a>
                      </li>
                      <li id="header--navitem:about">
                        <a href="#about">About</a>
                      </li>
                      <li id="header--navitem:education">
                        <a href="#education">Education</a>
                      </li>
                      <li id="header--navitem:work">
                        <a href="#work">Work</a>
                      </li>
                      <li id="header--navitem:contact">
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </header>
        </section>

        {/* Hero */}
        <section className="hero">
          <div className="hero--content">
            <div className="hero--typist-wrapper-outer">
              <div className="hero--typist-wrapper-inner">
                <Typist avgTypingDelay={100} startDelay={1000} cursor={{ show: true, blink: true, hideWhenDone: true }}>
                  <span className="hero--typist-name">Trevor Hill</span>
                  <Typist.Delay ms={1000}></Typist.Delay>
                  <br />
                  <Typist.Delay ms={1000}></Typist.Delay>
                  <span className="hero--typist-profession">Coder</span>
                  <Typist.Backspace count={5} delay={1500}></Typist.Backspace>
                  <span className="hero--typist-profession">Programmer</span>
                  <Typist.Backspace count={10} delay={1500}></Typist.Backspace>
                  <span className="hero--typist-profession">Computer Scientist</span>
                  <Typist.Backspace count={18} delay={1500}></Typist.Backspace>
                  <span className="hero--typist-profession"><b>Software Engineer.</b></span>
                </Typist>
              </div>
            </div>
          </div>
        </section>
        <section id="footer">
          <div className="hero--footer">
            <div className="footer--wrapper-inner">
              <nav className="footer--navitems-wrapper">
                <ul className="footer--navitems">
                  <li className="footer--navitem" id="footer--navitem:linkedin">
                    <SocialIcon url="https://www.linkedin.com/in/trevor-hill-062855149/" />
                  </li>
                  <li className="footer--navitem" id="footer--navitem:github">
                    <SocialIcon url="https://www.github.com/hilldaddyjr/" />
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </div >

      {/* About */}
      < section id="about" >
        <div className="about">
          <h1>About</h1>
          <div className="about--content">
            <div className="about--content-tile">
              <div className="about--content-tile-image">
                <img src="/assets/portrait.jpg" alt="My Portrait"></img>
              </div>
            </div>
            <div className="about--content-tile">
              <h2>Trevor Hill</h2>
              <p>Student by day, student by night,
                aspiring entrepreneur somewhere in between.
              </p>
            </div>
          </div>
        </div>
      </section >

      {/* Education */}
      < section id="education">
        <div className="education">
          <h1>Education</h1>
          <div className="education--content">
            <div className="education--content-tile">
              <div className="education--education-list">
                <ul>
                  <li><b>BS:</b> Computer Science 2020</li>
                  <li><b>MS:</b> Computer Science 2021</li>
                  <li><b>Concentrations:</b>
                    <ul className="education--content-sublist">
                      <li>Software Engineering</li>
                      <li>Systems Programming</li>
                      <li>Security</li>
                    </ul>
                  </li>
                  <li><b>Minor:</b> Chinese Language</li>
                </ul>
              </div>
            </div>
            <div className="education--content-tile">
              <div className="education--content-tile-image">
                <img src="/assets/university-arch.jpg" alt="University Arch" />
              </div>
            </div>
          </div>
        </div>
      </section >

      {/* Work */}
      < section id="work" >
        <div className="work">
          <h1>Work</h1>
          <div className="work--content">
            <p>Resume, work history, active projects, and hobbies coming soon!</p>
          </div>
        </div>
      </section >

      {/* Contact */}
      < section id="contact" >
        <div className="contact">
          <h1>Contact</h1>
          <div className="contact--content">
            <p>Questions or Inquiries? Email Me!</p>
            <address>
              <a href="mailto:contact@trevorhill.name">contact@trevorhill.name</a>
            </address>
          </div>
        </div>
      </section >
    </div >
  );
}

export { App };
